@import "../../theme.scss";

.button {
  background-image: linear-gradient(to right, $button-blue, $red);
  padding: 10px;
  border: none;
  border-radius: 20px;
  color: $off-white;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(to left, $button-blue, $red);
  }
}
