$blue: #141b4d;
$button-blue: #161f5b;
$red: #f9423a;
$off-white: #faf7f2;
$light-blue: #00c1d4;
$purple: #bb29bb;

@mixin devices($breakpoint) {
  //the name of the mixin is devices

  @if $breakpoint == tablet {
    @media only screen and (max-width: 1107px) {
      @content;
    }
  }

  @if $breakpoint == tablet-only {
    @media only screen and (min-width: 730px) and (max-width: 1107px) {
      @content;
    }
  }

  @if $breakpoint == hide-nav {
    @media only screen and (max-width: 729px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 680px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (min-width: 1108px) {
      @content;
    }
  }
}
