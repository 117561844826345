@import "../../theme.scss";

.nav-menu {
  margin-bottom: 20px;

  & .menu-link {
    margin-right: 30px;
    font-family: "Helvetica Neue", sans-serif;
    color: $off-white;
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: none;
    &:hover {
      color: $red;
    }
    &.is-active {
      text-decoration: underline $red;
      text-underline-offset: 5px;
      &:hover {
        color: $off-white;
      }
    }
    &:last-of-type {
      margin: 0;
    }
  }
  @include devices(hide-nav) {
    display: none;
  }
}

.mobile-nav {
  display: none;
  @include devices(hide-nav) {
    display: block;
  }
  .menu-button {
    cursor: pointer;
    font-size: 50px;
  }
  .mobile-menu {
    background: $blue;
    color: $off-white;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    & .close {
      font-size: 40px;
      font-weight: normal;
      margin-right: 10px;
      transform: rotate(45deg);
      display: block;
      cursor: pointer;
      float: right;
      top: 5px;
      position: relative;
    }
    & .mobile-links {
      padding-right: 30px;
      & > a {
        display: block;
        text-align: right;
        color: $off-white;
        text-decoration: none;
        text-transform: uppercase;
        font-family: "helvetica neue", sans-serif;
        padding: 20px 20px;

        &:hover {
          color: $red;
        }
      }
    }
  }
}
