@import "../../theme.scss";

.header {
  display: flex;
  color: $off-white;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .logo {
    max-height: 80px;
  }
  @include devices(tablet) {
    .logo {
      max-height: 50px;
    }
  }
}
