@import "../../theme.scss";

.App {
  background-color: $blue;
  padding: 30px 30px 0 30px;
  max-width: 1400px;
  margin: 0 auto;
  .main-app-body {
    background-color: $off-white;
    padding: 30px 0 0;
    margin-top: 30px;
    border-radius: 50px 50px 0 0;
  }
}
