@import "./theme.scss";

@font-face {
  font-family: "FreightTextPro";
  src: local("FreightTextPro"),
    url("./assets//fonts/freighttext-pro-medium.otf");
}

body {
  margin: 0;
  padding: 0;
  font-family: "FreightTextPro", serif;
  color: $blue;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $blue;
  .bold {
    font-weight: bold;
  }
  h1 {
    font-family: "Helvetica Neue", sans-serif;
    color: $red;
    font-size: 42px;
    line-height: 38px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  h2 {
    font-family: "Helvetica Neue", sans-serif;
    color: $blue;
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 0;
  }

  h3 {
    font-family: "Helvetica Neue", sans-serif;
    color: $red;
    font-size: 16px;
    margin-bottom: 5px;
  }
  a {
    color: $red;
  }
  .icon {
    width: 24px;
    height: 24px;
  }

  .is-loading {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hidden-on-mobile {
    @include devices(tablet) {
      display: none;
    }
  }
  .hidden-on-desktop {
    @include devices(desktop) {
      display: none;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
