@import "../../theme.scss";

.footer {
  padding: 20px 0;
  color: $off-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .call-to-action {
    font-family: "Helvetica Neue", sans-serif;
    font-weight: bold;
  }
  .footer-links {
    min-width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .usoc-logo {
      height: 24px;
    }
  }
  @include devices(tablet) {
    display: block;
    .cta {
      min-width: 100%;
      padding-bottom: 10px;
      margin-bottom: 30px;
      text-align: center;
      border-bottom: 1px solid $red;
      .call-to-action {
        display: block;
        padding-bottom: 10px;
      }
      .sign-up-button {
        display: block;
        text-align: center;
        margin: 10px auto;
      }
    }
  }
}
